<template>
  <div
      class="d-flex"
      :class="$store.getters['layout/getInnerWidth'] >= 991 ? 'justify-content-center' : 'justify-content-left'"
  >
    <div :class="badgeClass">
      <div
          class="text-center"
          :class="badgeText"
      >
        {{ participants }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    participants: {
      type: String,
      default: this
    },
    badgeClass: {
      type: String,
      default: 'badge-score-pending'
    },
    badgeText: {
      type: String,
      default: 'badge-text-m'
    }
  },
}
</script>
